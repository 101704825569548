import React, { Dispatch } from "react";
import { decrementNumberModal, incrementNumberModal } from "../store/slice";
import { AnyAction } from "redux";

const modals: React.ReactElement[] = [];
export default modals;

export function addModal(
  dispatch: Dispatch<AnyAction>,
  modalComp: React.ReactElement
) {
  modals.push(modalComp);
  dispatch(incrementNumberModal());
}

export function popModal(dispatch: Dispatch<AnyAction>) {
  dispatch(decrementNumberModal());
  modals.pop();
}
