import React, { useEffect, useState } from "react";
import { addModal, popModal } from "../services/modalService";
import { useSelector, useDispatch } from "react-redux";
import {
  decrementNumberModal,
  incrementNumberModal,
  setMainColor,
  setMenuColor,
  setSecondaryColor,
  setSelectedMenuColor,
  setThirdColor,
} from "../store/slice";
import {
  useMainColor,
  useMenuColor,
  useSecondaryColor,
  useSelectedMenuColor,
  useThirdColor,
} from "../hooks/colorHooks";
import { SketchPicker } from "react-color";
import { Configuration } from "../Configuration";
import { API } from "aws-amplify";
import { CircularProgress } from "@mui/material";
import ColorButton from "./ColorButton";

export default function ConfigurationModal(): React.ReactElement {
  const dispatch = useDispatch();
  const mainColor = useMainColor();
  const secondaryColor = useSecondaryColor();
  const thirdColor = useThirdColor();
  const menuColor = useMenuColor();
  const selectedMenuColor = useSelectedMenuColor();

  console.log("mainColor:" + mainColor);

  return (
    <div>
      <ColorButton
        colorName="main color"
        color={mainColor}
        setColor={(color) => {
          dispatch(setMainColor(color));
        }}
      />
      <ColorButton
        colorName="secondary color"
        color={secondaryColor}
        setColor={(color) => {
          dispatch(setSecondaryColor(color));
        }}
      />
      <ColorButton
        colorName="third color"
        color={thirdColor}
        setColor={(color) => {
          dispatch(setThirdColor(color));
        }}
      />
      <ColorButton
        colorName="menu color"
        color={menuColor}
        setColor={(color) => {
          dispatch(setMenuColor(color));
        }}
      />
      <ColorButton
        colorName="selected menu color"
        color={selectedMenuColor}
        setColor={(color) => {
          dispatch(setSelectedMenuColor(color));
        }}
      />
      {/* <button
        className="myfirst-button margin-class"
        style={{ backgroundColor: secondaryColor, color: thirdColor }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal
              label="main color : "
              color={mainColor}
              setColor={(color) => {
                dispatch(setMainColor(color));
              }}
            />
          );
        }}
      >
        main color
      </button>
      <br />
      <button
        className="myfirst-button margin-class"
        style={{ backgroundColor: secondaryColor, color: thirdColor }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal
              label="secondary color : "
              color={secondaryColor}
              setColor={(color) => {
                dispatch(setSecondaryColor(color));
              }}
            />
          );
        }}
      >
        secondary color
      </button>
      <br />
      <button
        className="myfirst-button margin-class"
        style={{ backgroundColor: secondaryColor, color: thirdColor }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal
              label="third color : "
              color={thirdColor}
              setColor={(color) => {
                dispatch(setThirdColor(color));
              }}
            />
          );
        }}
      >
        third color
      </button>
      <br />

      <button
        className="myfirst-button margin-class"
        style={{ backgroundColor: secondaryColor, color: thirdColor }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal
              label="third color : "
              color={menuColor}
              setColor={(color) => {
                dispatch(setMenuColor(color));
              }}
            />
          );
        }}
      >
        menu color
      </button>
      <button
        className="myfirst-button margin-class"
        style={{ backgroundColor: secondaryColor, color: thirdColor }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal
              label="third color : "
              color={selectedMenuColor}
              setColor={(color) => {
                dispatch(setSelectedMenuColor(color));
              }}
            />
          );
        }}
      >
        selected menu color
      </button>
      <br /> */}
    </div>
  );
}

// <ColorModal
//   color={color}
//   setColor={(newColor) => {
//     setColor(newColor);
//     // newColor = newColor.substring(1);
//     // dispatch(setMainColor(newColor));
