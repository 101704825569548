import React from "react";
import { useDispatch } from "react-redux";
import { addModal } from "../services/modalService";
import ColorModal from "./ColorModal";

type Props = {
  colorName: string;
  color: string;
  setColor: (a: string) => void;
};

export default function ColorButton(props: Props) {
  const dispatch = useDispatch();
  return (
    <div className="color-button-container">
      <button
        className="margin-class"
        style={{ backgroundColor: "lightgray", color: "black" }}
        onClick={() => {
          addModal(
            dispatch,
            <ColorModal colorName={props.colorName} setColor={props.setColor} />
          );
        }}
      >
        {props.colorName}
      </button>
      <span> ({props.color}) </span>
      <div className="color-div" style={{ backgroundColor: props.color }}></div>
    </div>
  );
}
