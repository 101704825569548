import React from "react";
import { useDispatch } from "react-redux";
import { useSecondaryColor, useThirdColor } from "../../../hooks/colorHooks";
import { setScreen } from "../../../store/slice";

export default function Home() {
  const secondaryColor = useSecondaryColor();
  const thirdColor = useThirdColor();
  const dispatch = useDispatch();
  return (
    <div className="inner-board-screen home-screen">
      <div className="centered-in-screen" style={{ color: thirdColor }}>
        Home
        <br />
        (parameters and profile with photos, description and hobbies)
        <br />
        <br />
        <button
          className="myfirst-button"
          style={{ backgroundColor: secondaryColor, color: thirdColor }}
          onClick={() => {
            dispatch(setScreen("welcome"));
          }}
        >
          logout
        </button>
      </div>
    </div>
  );
}
