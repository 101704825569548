import userEvent from "@testing-library/user-event";
import React from "react";
import { useMenuColor, useSelectedMenuColor } from "../../../hooks/colorHooks";

import { User } from "./InRoom";

type Props = {
  user: User;
  setSelectedUser: (user: User) => void;
};

export default function UserDiv(props: Props) {
  const menuColor = useMenuColor();
  const selectedMenuColor = useSelectedMenuColor();
  const user = props.user;
  return (
    <div
      className={`user-div ${user.girl ? "user-girl" : "user-boy"}`}
      style={{ backgroundColor: user.girl ? menuColor : selectedMenuColor }}
      onClick={() => {
        props.setSelectedUser(user);
      }}
    >
      <span className="inner-user-div">{user.name} [photo]</span>
    </div>
  );
}
