import userEvent from "@testing-library/user-event";
import React from "react";
import { useSecondaryColor } from "../../../hooks/colorHooks";
import { User } from "./InRoom";

type Props = {
  user: User;
  back: () => void;
};

export default function UserComponent(props: Props) {
  const user = props.user;
  const secondaryColor = useSecondaryColor();
  return (
    <div>
      <button
        className="myfirst-button"
        style={{ backgroundColor: secondaryColor }}
        onClick={props.back}
      >
        Back
      </button>
      <br />
      <br />
      <div>
        Here you can view all {user.name}'s photos, description and hobbies. You
        can chat whith and choose him/her too.
      </div>
    </div>
  );
}
