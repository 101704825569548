import { Label } from "@mui/icons-material";
//import { ColorPicker, ColorBox } from "material-ui-color";
import ColorPicker from "material-ui-color-picker";
import React from "react";
import { reduxForm, Field } from "redux-form";
import { SketchPicker } from "react-color";
import { color } from "@mui/system";
import {
  useMainColor,
  useMenuColor,
  useSecondaryColor,
  useSelectedMenuColor,
  useThirdColor,
} from "../hooks/colorHooks";
import Device from "./app/Device";

type Props = {
  colorName: string;
  setColor: (a: string) => void;
};

export default function ColorModal(props: Props) {
  const mainColor = useMainColor();
  const secondaryColor = useSecondaryColor();
  const thirdColor = useThirdColor();
  const menuColor = useMenuColor();
  const selectedMenuColor = useSelectedMenuColor();
  const color = getColor(
    props.colorName,
    mainColor,
    secondaryColor,
    thirdColor,
    menuColor,
    selectedMenuColor
  );
  const label = `${props.colorName} (${color}) : `;
  return (
    <div className="color-modal">
      <div className="color-modal-input">
        <label style={{ verticalAlign: "center" }}>{label}</label>
        <br />
        <SketchPicker
          disableAlpha
          color={color}
          onChange={(aColor) => {
            const str = `rgba(${aColor.rgb.r}, ${aColor.rgb.g}, ${aColor.rgb.b}, ${aColor.rgb.a})`;
            props.setColor(rgbaToHex(str));
          }}
        />
      </div>
      <div className="color-modal-device-container">
        <Device />
      </div>
    </div>
  );
}

function getColor(
  colorName: string,
  mainColor: string,
  secondaryColor: string,
  thirdColor: string,
  menuColor: string,
  selectedMenuColor: string
) {
  switch (colorName) {
    case "main color":
      return mainColor;
    case "secondary color":
      return secondaryColor;
    case "third color":
      return thirdColor;
    case "menu color":
      return menuColor;
    case "selected menu color":
      return selectedMenuColor;
  }
}

const rgbaToHex = (color: string): string => {
  if (/^rgb/.test(color)) {
    const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

    // rgb to hex
    // eslint-disable-next-line no-bitwise
    let hex = `#${(
      (1 << 24) +
      (parseInt(rgba[0], 10) << 16) +
      (parseInt(rgba[1], 10) << 8) +
      parseInt(rgba[2], 10)
    )
      .toString(16)
      .slice(1)}`;

    // added alpha param if exists
    console.log("color", rgba);
    if (rgba[3]) {
      console.log("rgba", color);
      const alpha = Math.round(0o1 * (parseInt(rgba[3]) * 100));
      const hexAlpha = ((parseFloat(rgba[3]) * 255) | (1 << 8))
        .toString(16)
        .slice(1);
      //const hexAlpha = (alpha + 0x10000).toString(16).substr(-2).toUpperCase();
      hex += hexAlpha;
    }

    return hex;
  }
  return color;
};
