import React from "react";
import { useSelector } from "react-redux";
import { useMainColor, useThirdColor } from "../../hooks/colorHooks";
import { MyFirstScreen } from "../../store/slice";
import { TheRootState } from "../../store/store";
import Board from "./Board";
import Logo from "./Logo";
import Welcome from "./Welcome";

export default function Device() {
  const screen = useSelector<TheRootState>(
    (state) => state.reducer.screen
  ) as MyFirstScreen;
  const mainColor = useMainColor();
  const thirdColor = useThirdColor();
  return (
    <div
      className="device"
      style={{ backgroundColor: mainColor, color: thirdColor }}
    >
      {getScreenComponent(screen)}
    </div>
  );
}

function getScreenComponent(screen: MyFirstScreen) {
  if (screen === "welcome") {
    return <Welcome />;
  }
  return <Board />;
}
