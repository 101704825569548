import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSecondaryColor } from "../../../hooks/colorHooks";
import InRoom from "./InRoom";

export default function Rooms() {
  const [loading, setLoading] = useState<boolean>(true);
  const [squareSize, setSquareSize] = useState<number>(1);
  console.log("squareSize", squareSize);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  }, [squareSize]);
  const secondaryColor = useSecondaryColor();
  return (
    <div className="inner-board-screen rooms-screen">
      <div className="centered-in-screen">
        {loading ? (
          <div>
            <span className="loading-room-text">you'll join a room soon</span>
            <div className="loading-room-loader-container">
              <CircularProgress sx={{ color: secondaryColor }} />
            </div>
          </div>
        ) : (
          <InRoom squareSize={squareSize} setSquareSize={setSquareSize} />
        )}
      </div>
    </div>
  );
}
