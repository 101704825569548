import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Root } from "react-dom/client";
import { nodeModuleNameResolver } from "typescript";

export type RootState = {
  numberModals: number;
  mainColor: string;
  secondaryColor: string;
  thirdColor: string;
  menuColor: string;
  selectedMenuColor: string;
  screen: MyFirstScreen;
};

export type MyFirstScreen = "welcome" | "home" | "messages" | "rooms";

export const initialState: RootState = {
  numberModals: 0,
  mainColor: "#FFFFFF",
  secondaryColor: "#F6BE00",
  thirdColor: "#000000",
  menuColor: "#FFFFFF",
  selectedMenuColor: "lightgray",
  screen: "welcome",
};

const slice = createSlice({
  name: "myfirst-demo-slice",
  initialState,
  reducers: {
    incrementNumberModal: (state: RootState) => {
      state.numberModals++;
    },
    decrementNumberModal: (state: RootState) => {
      state.numberModals--;
    },
    setMainColor: (state: RootState, action: PayloadAction<string>) => {
      state.mainColor = action.payload;
    },
    setSecondaryColor: (state: RootState, action: PayloadAction<string>) => {
      state.secondaryColor = action.payload;
    },
    setThirdColor: (state: RootState, action: PayloadAction<string>) => {
      state.thirdColor = action.payload;
    },
    setMenuColor: (state: RootState, action: PayloadAction<string>) => {
      state.menuColor = action.payload;
    },
    setSelectedMenuColor: (state: RootState, action: PayloadAction<string>) => {
      state.selectedMenuColor = action.payload;
    },
    setScreen: (state: RootState, action: PayloadAction<MyFirstScreen>) => {
      state.screen = action.payload;
    },
  },
});

export const {
  incrementNumberModal,
  decrementNumberModal,
  setMainColor,
  setSecondaryColor,
  setThirdColor,
  setScreen,
  setMenuColor,
  setSelectedMenuColor,
} = slice.actions;
const reducer = slice.reducer;
export default reducer;
