import React from "react";
import { useThirdColor } from "../../../hooks/colorHooks";

export default function Messages() {
  const thirdColor = useThirdColor();
  return (
    <div className="inner-board-screen messages-screen">
      <div className="centered-in-screen" style={{ color: thirdColor }}>
        The messages from your match
      </div>
    </div>
  );
}
