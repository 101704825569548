import React from "react";
import { useDispatch } from "react-redux";
import { useSecondaryColor, useThirdColor } from "../../hooks/colorHooks";
import { setScreen } from "../../store/slice";
import Logo from "./Logo";

export default function Welcome() {
  const secondaryColor = useSecondaryColor();
  const thirdColor = useThirdColor();
  const dispatch = useDispatch();
  return (
    <div className="welcome">
      <Logo />
      <div className="welcome-button-container">
        <button
          className="myfirst-button"
          style={{ color: thirdColor, backgroundColor: secondaryColor }}
          onClick={() => {
            dispatch(setScreen("home"));
          }}
        >
          make your choice
        </button>
      </div>
    </div>
  );
}
