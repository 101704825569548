import { FavoriteBorder } from "@mui/icons-material";
import { Icon } from "@mui/material";
import React from "react";
import { useSecondaryColor } from "../../hooks/colorHooks";

export default function Logo() {
  const secondaryColor = useSecondaryColor();
  const textCss = `
  .small {
    font: 140px serif;
    fill: ${secondaryColor};
        text-anchor: middle;
    dominant-baseline: middle;
   
  }

  // svg path {
  //     animation: draw 1s linear alternate infinite;
  // }
  // @keyframes draw {
  //     from {
  //       stroke: yellow;
  //     } 
  //     to {
  //       stroke: green;
  //     }
  // }


`;

  const css = `<![CDATA[
    .outline {
      stroke:none; stroke-width:0;
    }
  ]]>`;

  return (
    <div className="logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        height="315"
        width="342"
        viewBox="20 -10 315 342"
      >
        <style>{textCss}</style>
        <defs>
          <style type="text/css">{css}</style>
          <g id="heart">
            <path
              className="heart"
              stroke={secondaryColor}
              pathLength="1"
              strokeWidth="16"
              //           d="M0 200 v-200 h200
              // a100,100 90 0,1 0,200
              // a100,100 90 0,1 -200,0
              // z"
              d="M0 200 v-200 h200 
    a100,100 90 0,1 0,200
    a100,100 90 0,1 -200,0
    z"
            />
          </g>
        </defs>
        <desc>a nearly perfect heart made of two arcs and a right angle</desc>
        <use xlinkHref="#heart" className="outline " fill="none" />
        <text x="50%" y="50%" textAnchor="middle" className="small">
          1
        </text>
        {/* <text x="50%" y="50%">
          AAAAAAAAAAAAAAAA
        </text> */}
      </svg>
    </div>
  );
}
