import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MyFirstScreen, setScreen } from "../../store/slice";
import { TheRootState } from "../../store/store";
import Home from "./connectedScreen/Home";
import Messages from "./connectedScreen/Messages";
import Rooms from "./connectedScreen/Rooms";
import { GroupOutlined, Message, MessageOutlined } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import {
  useMenuColor,
  useSecondaryColor,
  useSelectedMenuColor,
  useThirdColor,
} from "../../hooks/colorHooks";

export default function Board() {
  const secondaryColor = useSecondaryColor();
  const menuColor = useMenuColor();
  const selectedMenuColor = useSelectedMenuColor();
  const screen = useSelector<TheRootState>(
    (state) => state.reducer.screen
  ) as MyFirstScreen;
  const dispatch = useDispatch();
  return (
    <div className="board">
      <div className="board-screen">{getScreenComponent(screen)}</div>
      <div className="board-menu">
        <div
          className="board-menu-item"
          style={{
            backgroundColor: screen === "home" ? selectedMenuColor : menuColor,
          }}
          onClick={() => {
            dispatch(setScreen("home"));
          }}
        >
          <HomeIcon sx={{ color: secondaryColor }} />
        </div>
        <div
          className="board-menu-item"
          style={{
            backgroundColor:
              screen === "messages" ? selectedMenuColor : menuColor,
          }}
          onClick={() => {
            dispatch(setScreen("messages"));
          }}
        >
          <MessageOutlined sx={{ color: secondaryColor }} />
        </div>
        <div
          className="board-menu-item"
          style={{
            backgroundColor: screen === "rooms" ? selectedMenuColor : menuColor,
          }}
          onClick={() => {
            dispatch(setScreen("rooms"));
          }}
        >
          <GroupOutlined sx={{ color: secondaryColor }} />
        </div>
      </div>
    </div>
  );
}

function getScreenComponent(screen: MyFirstScreen): React.ReactElement {
  switch (screen) {
    case "welcome":
      return <div>error</div>;
    case "home":
      return <Home />;
    case "messages":
      return <Messages />;
    case "rooms":
      return <Rooms />;
  }
}
