import { useSelector } from "react-redux";
import { TheRootState } from "../store/store";

export function useMainColor(): string {
  return useSelector<TheRootState>(
    (state) => state.reducer.mainColor
  ) as string;
}

export function useSecondaryColor(): string {
  return useSelector<TheRootState>(
    (state) => state.reducer.secondaryColor
  ) as string;
}

export function useThirdColor(): string {
  return useSelector<TheRootState>(
    (state) => state.reducer.thirdColor
  ) as string;
}

export function useMenuColor(): string {
  return useSelector<TheRootState>(
    (state) => state.reducer.menuColor
  ) as string;
}

export function useSelectedMenuColor(): string {
  return useSelector<TheRootState>(
    (state) => state.reducer.selectedMenuColor
  ) as string;
}
