import { GridListClassKey } from "@material-ui/core";
import React, { useState } from "react";
import UserDiv from "./UserDiv";
import UserComponent from "./UserComponent";
import { useSecondaryColor, useThirdColor } from "../../../hooks/colorHooks";

type Props = {
  squareSize: number;
  setSquareSize: (a: number) => void;
};

export type User = {
  name: string;
  girl: boolean;
};

export default function InRoom(props: Props) {
  const users = getUsers(props.squareSize);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const secondaryColor = useSecondaryColor();
  const gridTemplateColumns = getGridTemplateColumns(props.squareSize);
  const thirdColor = useThirdColor();
  return selectedUser ? (
    <UserComponent
      user={selectedUser}
      back={() => {
        setSelectedUser(null);
      }}
    />
  ) : (
    <div className="in-room-div">
      <div className="grid-user" style={{ gridTemplateColumns }}>
        {users.map((user) => (
          <UserDiv
            key={user.name}
            user={user}
            setSelectedUser={setSelectedUser}
          />
        ))}
      </div>
      <br />
      <div>Here you will see the remained time to choose someone</div>
      <br />
      <div>
        <button
          className="myfirst-button"
          style={{ backgroundColor: secondaryColor, color: thirdColor }}
          onClick={() => {
            const newSquareSize =
              props.squareSize < maxSquareSize ? props.squareSize + 1 : 1;
            props.setSquareSize(newSquareSize);
          }}
        >
          I don't have feelings
        </button>
      </div>
    </div>
  );
}

const maxSquareSize = 5;

const users: User[] = [
  {
    name: "Pierre",
    girl: false,
  },
  {
    name: "Eloïse",
    girl: true,
  },
  {
    name: "Max",
    girl: false,
  },
  {
    name: "Hajar",
    girl: true,
  },

  {
    name: "Pierre 2",
    girl: false,
  },
  {
    name: "Eloïse 2",
    girl: true,
  },
  {
    name: "Max 2",
    girl: false,
  },
  {
    name: "Hajar 2",
    girl: true,
  },

  {
    name: "Pierre 3",
    girl: false,
  },
  {
    name: "Eloïse 3",
    girl: true,
  },
  {
    name: "Max 3",
    girl: false,
  },
  {
    name: "Hajar 3",
    girl: true,
  },

  {
    name: "Pierre 4",
    girl: false,
  },
  {
    name: "Eloïse 4",
    girl: true,
  },
  {
    name: "Max 4",
    girl: false,
  },
  {
    name: "Hajar 4",
    girl: true,
  },

  {
    name: "Pierre 5",
    girl: false,
  },
  {
    name: "Eloïse 5",
    girl: true,
  },
  {
    name: "Max 5",
    girl: false,
  },
  {
    name: "Hajar 5",
    girl: true,
  },

  {
    name: "Pierre 6",
    girl: false,
  },
  {
    name: "Eloïse 6",
    girl: true,
  },
  {
    name: "Max 6",
    girl: false,
  },
  {
    name: "Hajar 6",
    girl: true,
  },

  {
    name: "Pierre 7",
    girl: false,
  },
  {
    name: "Eloïse 7",
    girl: true,
  },
  {
    name: "Max 7",
    girl: false,
  },
  {
    name: "Hajar 7",
    girl: true,
  },
];

function getUsers(squareSize: number): User[] {
  if (squareSize === 1) {
    return users.slice(0, 2);
  }
  const numberUsers = squareSize * squareSize;
  if (squareSize % 2 === 1) {
    return users.slice(0, numberUsers);
  }
  let result: User[] = [];
  for (let index = 0; index < squareSize; index++) {
    const additionalUsers = users.slice(
      index * squareSize,
      (index + 1) * squareSize
    );
    if (index % 2 === 1) {
      const removed = additionalUsers.shift();
      if (removed) {
        additionalUsers.push(removed);
      }
    }
    result = [...result, ...additionalUsers];
  }
  return result;
}

function getGridTemplateColumns(squareSize: number): string {
  let str = "";
  let prefix = "";
  if (squareSize === 1) {
    return "100px";
  }
  for (let index = 0; index < squareSize; index++) {
    str += prefix + "1fr";
    prefix = " ";
  }
  return str;
}
