import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";

import { CircularProgress, Input } from "@mui/material";
import { setupStore, TheRootState } from "./store/store";
import { Provider } from "react-redux";
import Modal from "./modals/Modal";
import { useSelector, useDispatch } from "react-redux";
import { addModal } from "./services/modalService";
import ConfigurationModal from "./components/ConfigurationModal";
import {
  setMainColor,
  setMenuColor,
  setSecondaryColor,
  setSelectedMenuColor,
  setThirdColor,
} from "./store/slice";
import { AnyAction } from "redux";
import {
  useMainColor,
  useMenuColor,
  useSecondaryColor,
  useSelectedMenuColor,
  useThirdColor,
} from "./hooks/colorHooks";
import { SketchPicker } from "react-color";
import Logo from "./components/app/Logo";
import Device from "./components/app/Device";
import { API } from "aws-amplify";
import { Configuration } from "./Configuration";

const store = setupStore();

function App() {
  const dispatch = useDispatch();

  const mainColor = useMainColor();
  const secondaryColor = useSecondaryColor();
  const thirdColor = useThirdColor();
  const menuColor = useMenuColor();
  const selectedMenuColor = useSelectedMenuColor();

  const numberModals = useSelector<TheRootState>(
    (state) => state.reducer.numberModals
  ) as number;

  const [configurations, setConfigurations] = useState<Configuration[]>();
  const [selectedConfigurationStr, setSelectedConfigurationStr] = useState("");

  useEffect(() => {
    (async function () {
      try {
        const myInit = {
          headers: {}, // OPTIONAL
          response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        };
        const resp = await API.get("myfirstAPI", "/colors", myInit);
        setConfigurations(resp.data.configs);
      } catch (error) {
        alert("can't load configurations");
      }
    })();
  }, []);
  return configurations ? (
    <div className="App" style={{ backgroundColor: mainColor }}>
      <h1 style={{ color: secondaryColor }}>myfirst demo</h1>
      <div className="configurations-panel">
        <label style={{ color: thirdColor }}>select configuration </label>
        <select
          value={selectedConfigurationStr}
          className="configurations-select"
          onChange={(e) => {
            setSelectedConfigurationStr(e.target.value);
            const currentConfiguration = configurations.find(
              (conf) => conf.name === e.target.value
            );
            if (!currentConfiguration) {
              dispatch(setMainColor("#FFFFFF"));
              dispatch(setSecondaryColor("#F6BE00"));
              dispatch(setThirdColor("#000000"));
              dispatch(setMenuColor("#FFFFFF"));
              dispatch(setSelectedMenuColor("lightgray"));
              return;
            }
            dispatch(setMainColor(currentConfiguration.mainColor));
            dispatch(setSecondaryColor(currentConfiguration.secondaryColor));
            dispatch(setThirdColor(currentConfiguration.thirdColor));
            dispatch(setMenuColor(currentConfiguration.menuColor));
            dispatch(
              setSelectedMenuColor(currentConfiguration.selectedMenuColor)
            );
          }}
        >
          <option key="" value=""></option>
          {configurations.map((configuration) => (
            <option key={configuration.name} value={configuration.name}>
              {configuration.name}
            </option>
          ))}
        </select>
        <button
          className="myfirst-button"
          style={{ backgroundColor: secondaryColor, color: thirdColor }}
          onClick={async () => {
            const name = window.prompt("config name : ");
            if (!name) {
              return;
            }
            const newConfiguration: Configuration = {
              name,
              mainColor,
              secondaryColor,
              thirdColor,
              menuColor,
              selectedMenuColor,
            };
            setConfigurations([...configurations, newConfiguration]);
            setSelectedConfigurationStr(newConfiguration.name);
            const myInit = {
              headers: {}, // OPTIONAL
              body: newConfiguration, // OPTIONAL (return the entire Axios response object instead of only response.data)
            };
            if (name) {
              try {
                await API.put("myfirstAPI", "/colors", myInit);
                alert("config saved");
              } catch (error) {
                alert("can't save config");
              }
            }
          }}
        >
          save colors in new configuration
        </button>
      </div>
      <div className="app-button-container">
        <button
          className="myfirst-button"
          style={{ backgroundColor: secondaryColor, color: thirdColor }}
          onClick={() => {
            addModal(dispatch, <ConfigurationModal />);
          }}
        >
          config colors
        </button>
      </div>
      <Device />
      {numberModals > 0 && <Modal />}
    </div>
  ) : (
    <CircularProgress />
  );
}

export default App;
