import React from "react";
import { useDispatch, useSelector } from "react-redux";
import modals, { addModal, popModal } from "../services/modalService";
import { TheRootState } from "../store/store";
import ClearIcon from "@mui/icons-material/Clear";
import { useMainColor } from "../hooks/colorHooks";

type Props = {
  index: number;
};

export default function ModalElement(props: Props) {
  const dispatch = useDispatch();
  const numberModals = useSelector<TheRootState>(
    (state) => state.reducer.numberModals
  ) as number;
  const index = props.index;
  const theModalElement = modals[index];
  return (
    <div className="modal-element">
      <div
        className="outside-modal"
        onClick={() => {
          popModal(dispatch);
        }}
      ></div>
      <span className="vertical-center-helper"></span>
      <div className="inside-modal">
        <div className="inside-modal-inner">
          <div className="inner-modal-element">{theModalElement}</div>
        </div>
        <div className="close-modal-icon">
          <ClearIcon
            onClick={() => {
              popModal(dispatch);
            }}
          />
        </div>
        {index < numberModals - 1 && <ModalElement index={index + 1} />}
      </div>
    </div>
  );
}
