import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import reducer, { RootState } from "./slice";

export type TheRootState = {
  reducer: RootState;
};

export function setupStore(preloadedState?: PreloadedState<TheRootState>) {
  return configureStore({
    reducer: { reducer },
    preloadedState,
    devTools: true,
  });
}

export type AppStore = ReturnType<typeof setupStore>;
