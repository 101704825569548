import React from "react";
import ModalElement from "./ModalElement";

export default function Modal() {
  return (
    <div className="modals-container">
      <ModalElement index={0} />
    </div>
  );
}
